
export const exports = Setup();

async function Setup() {
    //(relativePath ? relativePath :
    const mainJsPath =  '../../wasm/net8.0/wwwroot/_framework/dotnet.js';
    const {dotnet} = await import(/*webpackIgnore: true */mainJsPath) ;
    const { getAssemblyExports, getConfig } = await dotnet
    .withDiagnosticTracing(false)
    .withApplicationArgumentsFromQuery()
    .create();
    const config = getConfig();
    const exports = await getAssemblyExports(config.mainAssemblyName);
    
    //await dotnet.run();
    return exports;
}





