import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../store"
import { loadFirmDetails, loadFirms, loadUserNames, selectAdmin, setSelectedFirm } from "./adminStore"
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utils/authConfig";
import { FirmDisplay } from "./FirmDisplay";
import { UserAdministration } from "./UserAdministration";
import { Connections } from "../connections/Connections";


export function FirmAdministration() {
    const { firms, selectedFirm, firmLoading } = useAppSelector(selectAdmin);
    const dispatch = useAppDispatch();
    const { instance, accounts } = useMsal();

    useEffect(() => {

        const login = async () => {
            const account = instance.getAccountByHomeId(accounts[0].homeAccountId);
            instance.setActiveAccount(account);
            try {
                await instance.acquireTokenSilent({ ...loginRequest });
            }
            catch {
                await instance.acquireTokenRedirect({ ...loginRequest });
            }
            dispatch(loadUserNames());
            dispatch(loadFirms());
        }

        login();
    }, []);

    if (!firms) {
        return (<div><span>Waiting for firms to load</span></div>)
    }

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch(setSelectedFirm(Number(event.target.value)));
        dispatch(loadFirmDetails());
    }

    return (<div className="mainContainer">        
        <div className="firmSelect">
            <div className="headingStyle" style={{ padding: 5 }}>Firm:</div>
            <select className="firmSelectComponent" onChange={handleSelect} value={selectedFirm || ''}>
                <option key="selectAFirm" value="">Select a firm</option>
                {firms.map(firm => (
                    <option key={firm.id} value={firm.id}>
                        {firm.name}
                    </option>))}
            </select>
        </div>
        {!selectedFirm ? <Connections/> : null}
        {firmLoading ? <span>Loading</span> : null}
        {selectedFirm && !firmLoading ? <FirmDisplay/> : null}
        <UserAdministration/>
    </div>)
}