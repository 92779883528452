import moment from "moment";

export function isDebug() {
    const isItDebug = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "";
    console.log('IsDebug is ', isItDebug);
    return isItDebug;
}

export const formatStamp = (stamp: string | null) => {
    if (!stamp)
        return '-';
    var m = moment.utc(stamp, "YYYY-MM-DDTHH:mm:ss.SSSSSSSSZ")
    if (m.clone().startOf('day').isSame(moment().utc().startOf('day')))
        return m.format("HH:mm:ss");

    return m.format("DD/MM HH:mm:ss");
}