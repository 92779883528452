import { loadFirmDetails, loadUserDetails, selectAdmin, setSelectedFirm } from "../admin/adminStore";
import { useAppDispatch, useAppSelector } from "../store"
import { formatStamp } from "../utils/utils";
import { loadConnectionDetails, selectConnections } from "./connectionStore";
import { useEffect } from "react";

export function Connections() {
    const dispatch = useAppDispatch();
    const { connectedUsers, loading } = useAppSelector(selectConnections);
    const { userNames, firms } = useAppSelector(selectAdmin);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(loadConnectionDetails());
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    const capitalizeName = (name: string) => name === "Gav" ? name : name.toUpperCase();

    const onClickUserName = (userId: number, firmName: string) => {
        const firm = firms?.find(f => capitalizeName(f.customerKey) === firmName);
        if (firm) {
            dispatch(loadUserDetails(userId))
            dispatch(setSelectedFirm(Number(firm.id)));
            dispatch(loadFirmDetails())
        }
    }

    const onClickFirmName = (firmName: string) => {
        const firm = firms?.find(f => capitalizeName(f.customerKey) === firmName);
        console.log(firmName);
        console.log(firms);
        if (firm) {
            console.log("Switch to firm " + firm.id)
            dispatch(setSelectedFirm(Number(firm.id)));
            dispatch(loadFirmDetails())
        }
    }

    return (<div className="dataContainer">
        <div className="dataBox" style={{ width: "70%" }}>
            <span className="headingStyle" style={{ textAlign: "center", padding: 5 }}>Connections</span>
            <table>
                <thead>
                    <tr>
                        <th>Firm</th>
                        <th>User Name</th>
                        <th>User Id</th>
                        <th>Last Logged In</th>
                        <th>Last Logged Out</th>
                        <th># Active Conn</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(connectedUsers).map(key => {
                        return connectedUsers[key]?.users?.map(user => {
                            const name = userNames[user.userId];
                            return (<tr key={key + user.userId}>
                                <td style={{ cursor: 'pointer' }} onClick={() => onClickFirmName(key)}>{key}</td>
                                <td style={{ cursor: 'pointer' }} onClick={() => onClickUserName(user.userId, key)}>{name}</td>
                                <td>{user.userId}</td>
                                <td>{formatStamp(user.lastLoggedIn)}</td>
                                <td>{formatStamp(user.lastLoggedOut)}</td>
                                <td>{user.numberOfCurrentConnections}</td>
                            </tr>)
                        })
                    })}
                </tbody>
            </table>
        </div>
    </div>)
}