import { useState } from "react";
import { selectConnections } from "../connections/connectionStore";
import { useAppDispatch, useAppSelector } from "../store"
import { formatStamp } from "../utils/utils";
import { loadUserDetails, selectAdmin } from "./adminStore"
import { UsernamePassword } from "./adminModels";
import { libPost } from "../utils/httpUtils";

const capitalizeName = (name:string) => name==="Gav" ? name : name.toUpperCase();

export const UserAdministration = () => {
    const { selectedUser, selectedFirm, userLoading, firms } = useAppSelector(selectAdmin);
    const [editingCreds, SetEditingCreds] = useState<UsernamePassword | null>(null);
    const [savedMessage, SetSavedMessage] = useState<String | null>(null);
    const { connectedUsers } = useAppSelector(selectConnections);
    const dispatch = useAppDispatch();
    const userConnection = connectedUsers[capitalizeName(firms?.find(x => x.id === selectedFirm)?.name ?? "")]?.users?.find(x => x.userId === selectedUser?.user?.userId);
    if (userLoading) return (<span>Loading....</span>)
    if (!selectedUser) return (<></>)

    const handleNewCreds = () => {
        if(!selectedUser || !selectedUser.user) return;
        SetEditingCreds({ username : '', password : '', type : '', userId: selectedUser.user?.userId});
    }

    const handleSave = async () => {
        let creds = editingCreds;
        SetEditingCreds(null);
        SetSavedMessage('Saving ...');
        creds = await libPost<UsernamePassword>('credentials', creds);
        console.log('Creds to save ', creds);
        SetSavedMessage('Saved');
        dispatch(loadUserDetails(selectedUser?.user?.userId ?? 0));
    }

    return (<div className="userDetail">
        <div className="userDetailHead">
            <div className="userDetailSection">
                <div className="dataRow"><div className="headingStyle" style={{ width: "80px" }}>User Id</div><input type="text" readOnly value={selectedUser.user?.userId}></input></div>
                <div className="dataRow"><div className="headingStyle" style={{ width: "80px" }}>Name</div><input type="text" readOnly value={selectedUser.user?.name}></input></div>
            </div>
            <div className="userDetailSection">
                <div className="dataRow"><div className="headingStyle" style={{ width: "200px" }}>Active Connections</div>{userConnection?.numberOfCurrentConnections}</div>
                <div className="dataRow"><div className="headingStyle" style={{ width: "200px" }}>Last Logged In</div>{formatStamp(userConnection?.lastLoggedIn ?? null)}</div>
                <div className="dataRow"><div className="headingStyle" style={{ width: "200px" }}>Last Logged Out</div>{formatStamp(userConnection?.lastLoggedOut ?? null)}</div>
</div>
        </div>

        <div className="userDetailSection">
            <table className="userDetailTable">
                <thead>
                    <tr>
                        <th>Firm Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedUser.user?.firms?.map((f, ix) => (
                        <tr key={ix}>
                            <td>{f.name}</td>
                            <td><button>Remove</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    </div>

      <div className="userDetailSection">
            <table className="userDetailTable">
                <thead>
                    <tr>
                        <th>Squad</th>
                        <th>Firm</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedUser.user?.squadMaps?.map((s, ix) => (
                        <tr key={ix}>
                            <td>{s.squad.name}</td>
                            <td>{firms?.find(f => f.id === s.squad.firmId)?.name ?? "(All)"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    </div>

        <div className="userDetailSection">
            <table className="userDetailTable">
                <thead>
                    <tr>
                        <th>Id Type</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedUser.user?.userIdentifiers?.map((ui, ix) => (
                        <tr key={ix}>
                            <td>{ui.type}</td>
                            <td>{ui.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
                    </div>
        
        <div className="userDetailSection">
            <table className="userDetailTable">
                <thead>
                    <tr>
                        <th>Credential Type</th>
                        <th>Username</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedUser.credentials?.map((c, ix) => (
                        <tr key={ix}>
                            <td>{c.type}</td>
                            <td>{c.username}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={() => handleNewCreds()}>New Creds</button>
        </div>
        {editingCreds && (
            <div>
            <div>Username <input type="text" value={editingCreds.username} onChange={(e) => {
                SetEditingCreds({...editingCreds, username : e.target.value})
            }}></input></div>
            <div>Password <input type="password" value={editingCreds.password} onChange={(e) => {
                SetEditingCreds({...editingCreds, password : e.target.value})
            }}></input></div>
            <div>Type <select value={editingCreds.type} onChange={(e) => {
                SetEditingCreds({...editingCreds, type : e.target.value})
            }}>
                <option value="">Select Type</option>
                <option value="Barchart.com">Barchart.com</option>
                <option value="GoldmansLME">Goldmans LME</option>
            </select></div>
            <button onClick={() => handleSave()}>Save Credentials</button>
            </div>
        )}
        {savedMessage && (<span>{savedMessage}</span>)}
    </div>)
}