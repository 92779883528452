import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

export function AuthenticateComponent() {
    const {accounts, instance, inProgress} = useMsal();
    const callLogin = () => {
        const isAuthenticated = accounts.length > 0;
        const msalInstance = instance;

        if(!isAuthenticated && inProgress === InteractionStatus.None) {
            msalInstance.loginRedirect();
        }
    }
    
    useEffect(() => {
            callLogin();
    },[])
    

    return (<></>)
}
