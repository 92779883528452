import { useAppDispatch, useAppSelector } from "../store"
import { webhostPost } from "../utils/httpUtils";
import { loadUserDetails, selectAdmin } from "./adminStore"

export function FirmDisplay() {
    const {firmSquads, firmUsers, selectedFirm, firms} = useAppSelector(selectAdmin)
    const dispatch = useAppDispatch();

    const handleUserClick = (userId : number) => {
        dispatch(loadUserDetails(userId));
    }

    const handleForceRefresh = async () => {
        const firm = firms?.find(f => f.id === selectedFirm);
        if(!firm) return;

        await webhostPost(firm.apiLocation + '/api/User/reload');
    }

    return (<div className="dataContainer">
    <div className="dataBox">
        <button onClick={handleForceRefresh}>Force Client Refresh</button>
        <span className="headingStyle" style={{textAlign:"center", padding: 5}}>Squads</span>
        <table>
            <thead>
                <tr>
                    <th>Squad Id</th>
                    <th>Name</th>
                    <th>Firm Id</th>
                    <th>Roles</th>
                </tr>
            </thead>
            <tbody>
                {firmSquads?.map((s,ix) => (<tr key={ix}>
                    <td>{s.squadId}</td>
                    <td>{s.name}</td>
                    <td>{s.firmId ? 'Local' : 'Global'}</td>
                    <td>{s.roles?.join(',')}</td>
                </tr>))}
            </tbody>
        </table>
        </div>
        <div className="dataBox">
        <span className="headingStyle" style={{textAlign:"center", padding: 5}}>Users</span>
        <table>
            <thead>
                <tr>
                    <th>User Id</th>
                    <th>Name</th>
                    <th>Roles</th>
                </tr>
            </thead>
            <tbody>
                {firmUsers?.map((u,ix) => (
                    <tr key={ix}>
                        <td>{u.userId}</td>
                        <td><button onClick={() => handleUserClick(u.userId)}>{u.name}</button></td>
                        <td>{u.roles?.join(',')}</td>
                    </tr>
                )
                )}
            </tbody>
        </table>
        </div>
    </div>)
}