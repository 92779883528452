import axios, { AxiosError } from 'axios';
import { msalInstance } from '../App';
import { loginRequest } from './authConfig';
export const LibrarianLocation = "https://api.librarian.pltfm.app/api/"
//export const LibrarianLocation = "http://localhost:52244/api/"

type userTokenType = {
    userId: string | undefined;
    accessToken: string | undefined;
}

export const userToken = { userId: undefined, accessToken: undefined, isDevMode: true, context: undefined } as userTokenType;

export async function libGet<T>(api: string): Promise<T | null>{
    try {
        const token = await GetToken();
        const response = await axios.get<T>(LibrarianLocation + api, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        return response.data;
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error('Error fetching data:', axiosError.message);
            console.error('Error details:', axiosError.request, axiosError.config);
        } else {
            console.error('An unexpected error occurred:', error);
        }

        return null;
    }
}

export async function webhostPost(api : string) {
    try {
        const token = await GetToken();
        const response = await axios.post(api, null, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        });
    }
    catch(error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error('Error fetching data:', axiosError.message);
            console.error('Error details:', axiosError.request, axiosError.config);
        } else {
            console.error('An unexpected error occurred:', error);
        }
    }
}

export async function libPost<T>(api : string, body : unknown): Promise<T | null>{
    try {
        const token = await GetToken();
        const response = await axios.post<T>(LibrarianLocation + api, body, {
            headers : {
                'Authorization' : 'Bearer ' + token
            }
        });
        return response.data;
    }
    catch(error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.error('Error fetching data:', axiosError.message);
            console.error('Error details:', axiosError.request, axiosError.config);
        } else {
            console.error('An unexpected error occurred:', error);
        }

        return null;
    }
}

async function GetToken()
{
    let token = "";
    try{
        token = (await msalInstance.acquireTokenSilent({...loginRequest})).accessToken;
    }
    catch{
        await msalInstance.acquireTokenRedirect({ ...loginRequest });
        token = (await msalInstance.acquireTokenSilent({ ...loginRequest })).accessToken;
    }
    return token;

}