import React from 'react';
import './App.css';
import {store} from './store'
import { Provider } from 'react-redux';
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest, msalConfig } from './utils/authConfig';
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticateComponent } from './login/authenticateComponent';
import { FirmAdministration } from './admin/FirmAdministration';
import { WasmWrapper } from './webassembly/wasmWrapper';

export const msalInstance = new PublicClientApplication(msalConfig)

function App() {
  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <WasmWrapper>
            <FirmAdministration></FirmAdministration>
          </WasmWrapper>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        <AuthenticateComponent/>
        </UnauthenticatedTemplate>
      </MsalProvider>
      </Provider>
  );
}

export default App;
//<Provider store={store}>
//</Provider>