import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Firm, SquadInfo, User, UserDetails, UserInfo } from "./adminModels";
import { RootState } from "../store";
import { libGet } from "../utils/httpUtils";

interface adminStoreState {
    firms: Firm[] | null,
    firmUsers: UserInfo[] | null,
    firmLoading: boolean,
    firmSquads: SquadInfo[] | null,
    selectedFirm: number | null,
    selectedUser: UserDetails | null,
    userLoading: boolean,
    userNames: { [key: number]: string; };
}

const initialState: adminStoreState = {
    userNames: {},
    firms: null,
    firmUsers: null,
    firmLoading: false,
    firmSquads: null,
    selectedFirm: null,
    selectedUser: null,
    userLoading: false,
}

export const loadFirms = createAsyncThunk(
    'admin/loadFirms',
    async () => {
        try {
            console.log('Loading firms');
            const result = await libGet<Firm[]>('firms');
            return result;
        }
        catch { }
    }
)

export const loadUserDetails = createAsyncThunk(
    'admin/loadUserDetails',
    async (userId: number) => {
        const user = await libGet<UserDetails>('users?userId=' + userId.toString());
        console.log('User loaded ', user);
        return user;
    }
)

export const loadFirmDetails = createAsyncThunk(
    'admin/loadFirmDetails',
    async (_, { getState }) => {
        const state = getState() as RootState;
        const selectedFirm = state.admin.selectedFirm;
        if (!selectedFirm) {
            return { squads: null, users: null };
        }
        const users = await libGet<UserInfo[]>('users/all?firmId=' + selectedFirm.toString());
        const squads = await libGet<SquadInfo[]>('users/squads?firmId=' + selectedFirm.toString());

        return { squads: squads, users: users }
    }
)

export const loadUserNames = createAsyncThunk(
    'admin/loadUserNames',
    async () => {
        const userNames = await libGet<{ [key: number]: string; }>('users/usernames');
        return userNames;
    }
)

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setSelectedFirm: (state, action: PayloadAction<number>) => {
            state.selectedFirm = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFirms.fulfilled, (state, action) => {
                if (action.payload) {
                    state.firms = action.payload;
                }
            })
            .addCase(loadFirmDetails.pending, (state) => {
                state.firmLoading = true;
                state.selectedUser = null;
            })
            .addCase(loadFirmDetails.fulfilled, (state, action) => {
                state.firmLoading = false;
                state.firmUsers = action.payload?.users ?? null;
                state.firmSquads = action.payload?.squads ?? null;
            })
            .addCase(loadUserDetails.pending, (state) => {
                state.selectedUser = null;
                state.userLoading = true;
            })
            .addCase(loadUserDetails.fulfilled, (state, action) => {
                state.selectedUser = action.payload;
                state.userLoading = false;
            })
            .addCase(loadUserNames.fulfilled, (state, action) => {
                if (action.payload) {
                    state.userNames = action.payload;
                }
            })
    }
});

export default adminSlice.reducer;

export const selectAdmin = (state: RootState) => state.admin;

export const {
    setSelectedFirm
} = adminSlice.actions;