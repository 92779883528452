import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ConnectionsFirmResponse } from "./connectionModels";
import { RootState } from "../store";
import { libGet } from "../utils/httpUtils";


interface connectionStoreState {
    connectedUsers: { [key: string]: ConnectionsFirmResponse; },
    loading : boolean,
}

const initialState : connectionStoreState ={
    connectedUsers : {},
    loading : false,
}

export const loadConnectionDetails = createAsyncThunk(
    'connections/loadConnectionDetails',
    async () => {
        const connections = await libGet<{[key: string]: ConnectionsFirmResponse;}>('connections');
        return connections;
    }
)

const connectionSlice = createSlice({
    name : "connections",
    initialState,
    reducers : {

    },
    extraReducers : (builder) => {
        builder.addCase(loadConnectionDetails.fulfilled, (state, action) => {
            if(action.payload){
                state.connectedUsers = action.payload;
            }
            state.loading = false;
        })
        .addCase(loadConnectionDetails.pending, (state) => {
            state.loading = true;
        });
    }
});



export default connectionSlice.reducer;

export const selectConnections = (state: RootState) => state.connections;

export const {} = connectionSlice.actions;