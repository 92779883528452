import { configureStore } from "@reduxjs/toolkit";
import adminSlice from './admin/adminStore';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import connectionSlice from './connections/connectionStore';

export const store = configureStore({
    reducer : {
        admin : adminSlice,
        connections : connectionSlice
    },
    devTools : false,
    middleware : (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck :false
        })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;