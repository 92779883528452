import { PropsWithChildren, useEffect, useState } from "react";
import { GetOptionPrice } from "./wasmWrap";

type WasmWrapperProps = {

}

export const WasmWrapper = (props : PropsWithChildren<WasmWrapperProps>) => {
    const [optionName, setOptionName] = useState<string>();
    
    useEffect(() => {
        const start = async () => {
            console.log('Getting option price');
            const optionPrice = await GetOptionPrice();
            console.log('Got the option price ', optionPrice);
            setOptionName(optionPrice);
        };

        start();
    }, [])


    return (<div>
        <div>This is the option price {optionName}</div>
        {props.children}
    </div>)
}